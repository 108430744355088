import React from "react";
import Navigate from "../navigate";
import cx from "classnames";

function CategoryTitleBordered(props) {
  const className = cx(
    "top-border-category relative border-t-default font-montserrat border-gray-400 font-semibold pt-2 text-denim break-word",
    props.className,
    props.className?.includes("mb") || props.className?.includes("my") ? '' : 'mb-3',
    props.className?.includes("mt") || props.className?.includes("my") ? '' : 'mt-3',
  );

  const titleClass = cx(
    "flex-shrink-0 p-4 lg:p-0 uppercase",
    props.titleClass,
    props.titleClass?.includes("bg-") ? '' : 'bg-denim lg:bg-transparent',
    props.titleClass?.includes("pl-") ? '' : 'pl-4'
  );

  const borderClass = cx(
    "bg-denim w-full lg:ml-4",
    props.borderClass
  );

  const render = () => {
    return <div className={className} style={{minHeight: "60px"}} data-datocms-noindex>
      <div className={titleClass}>{props.title}</div>
      <div className={borderClass} style={{height: "0.5px"}}></div>
    </div>;
  }
  return <>
    {props.link
      ? <Navigate href={props.link}>
        {render()}
      </Navigate>
      : render()
    }
  </>;
}

export default CategoryTitleBordered;
