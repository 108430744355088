import React, {useState} from 'react'
import {graphql} from 'gatsby'
import loadable from '@loadable/component'
import Layout from '../components/layout/index.amp'
import {getArticleLinks} from '../utils/articleUtil'
import CategoryTitleBordered from '../components/helpers/categoryTitleBordered.amp'
import {map as _map} from 'lodash'

const ArticlePreview = loadable(() => import("./articlePreview.amp"));

const Homepage = props => {
    const displayedArticles = [];

  const getHeroArticle = () => {
    let featuredArticle = (props.data.featured.nodes || []).find(article => new Date(article['featuredTill']) >= new Date()) || props.data.editorsChoice.nodes[0];
    featuredArticle && displayedArticles.push(featuredArticle.id);
    return featuredArticle;
  };

  const [heroArticle, setHeroArticle] = useState(getHeroArticle());

  const excludeAlreadyDisplayed = (articles) => {
    return articles.filter(article => !displayedArticles.includes(article.id));
  }

    return (
        <Layout>
            <div className={"w-full pt-2"} data-datocms-noindex>
              {heroArticle && <a className={"flex-1"} href={getArticleLinks(heroArticle).getReadMore()}>
                <div className={"flex sm:flex-col flex-col-reverse relative h-full"}>
                  <div className={"px-8 text-white text-lg font-bold bg-denim absolute"}>NEWS</div>
                  <div
                    className={"h-full min-h-screen-30 bg-no-repeat bg-center bg-cover cursor-pointer flex items-end justify-end"}
                    style={{backgroundImage: `url(${heroArticle.cover.url})`, lineHeight: 1.2}}>
                    <img className={"m-2"} src={'/images/editors-choice.png'} width={80} height={80} alt={"featured"}/>
                  </div>
                  <div
                    className={"text-black p-2 text-center text-2xl font-bold leading-snug font-montserrat"}>
                    {heroArticle.heading}
                  </div>
                </div>
              </a>}
            </div>
            <main className={"wrapper mt-2 sm:mt-10"} data-datocms-noindex>
                <div className={"grid grid-cols-2 gap-10"}>
                  {_map(props.pageContext.indexPageCategoryWiseArticles, (data, key) => {
                    return <div className={"col-span-2"}>
                      <CategoryTitleBordered title={data.title} link={`/${key}`} className={"mb-4"}/>
                      <div className={"flex px-2"}>
                        {
                          excludeAlreadyDisplayed(data.articles).slice(0, 2).map((article, index) => {
                            displayedArticles.push(article.id);
                            return <ArticlePreview
                              key={`news-${index}`} data={article}
                              className={`flex-1 first:ml-0 last:mr-0 mx-2 ${index === 2 ? 'hidden md:block' : ''}`}
                              headingClass={"text-xl font-arial font-semibold sm:font-bold leading-snug"}
                              noBody={true}
                              noFlag={true}
                              noAuthor={true}
                              dateAsFlag={true}
                              publishedAtFormat={"mmmm dd, yyyy"}/>
                          })
                        }
                      </div>
                    </div>
                  })}
                </div>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query indexPage($top: [String], $currentDateTime: Date) {
        featured: allDatoCmsArticle(filter: {featuredTill: {gte: $currentDateTime}, website: {elemMatch: {name: {eq: "at"}}}}
            sort: {order: DESC, fields: publishedAt}
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
        editorsChoice: allDatoCmsArticle(filter: {category: {elemMatch: {originalId: {in: $top}}}, website: {elemMatch: {name: {eq: "at"}}}}
            sort: {order: DESC, fields: publishedAt}
            limit: 1
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
        topRight: allDatoCmsArticle(filter: {category: {elemMatch: {originalId: {in: $top}}}, website: {elemMatch: {name: {eq: "at"}}}}
            sort: {order: DESC, fields: publishedAt}
            limit: 4
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
    }
`

export default Homepage
